import React, { useState } from 'react'
import styled from 'styled-components'
import IconLink from './components/IconLink'
import CreatorModal from './components/CreatorModal'
import mobile from 'is-mobile'

const Container = styled.div`
  position: relative;
  padding-top: 60px;
  height: 100vh;
  width: 100%;

  @media (max-width: 1400px) {
    height: 100%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: 200;
  height: 100vh;
  padding-top: 60px;
  @media (max-width: 1400px) {
    height: 100%;
  }
`

const Title = styled.div`
  font-family: 'AvenirNextLTPro-Bold', Arial, serif;
  font-size: 3.75rem;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1;

  @media (max-width: 1280px) {
    padding-top: 100px;
    font-size: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`

const Subtitle = styled.div`
  font-family: 'AvenirNextLTPro-Regular', Arial, serif;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 2rem;

  @media (max-width: 1400px) {
    font-size: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-inline: auto;
  }

  @media (max-width: 1280px) {
    font-size: 1.125rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-inline: auto;
  }
`

const VideoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: -1;
  @media (max-width: 1400px) {
    height: 100%;
  }
`

const DarkCover = styled.div`
  position: absolute;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, black 100%);
  height: 100vh;
  @media (max-width: 1400px) {
    height: 100%;
  }
  z-index: -1;
`

const VideoBackground = styled.video`
  position: absolute;
  width: 100%;
  object-fit: cover;
  height: 100vh;
  @media (max-width: 1400px) {
    height: 100%;
  }
  z-index: -2;
`

const IconSection = styled.div`
  display: grid;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-cols: 3;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 1280px;

  @media (max-width: 1400px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: 100%;
    div:last-child {
      grid-column: span 2 / span 2;
    }
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    width: 100%;
    div:last-child {
      grid-column: span 1 / span 1;
    }
  }
`

const LearnMore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  opacity: 0.5;
  cursor: pointer;
  width: fit-content;
  font-size: 0.85rem;

  &:hover {
    opacity: 1;
  }

  div:first-child {
    margin-right: 0.5rem;
  }

  div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 1rem;
      height: 1rem;
    }
  }

  @media (max-width: 1280px) {
    display: none;
  }
`

const WelcomeSection = () => {
  const [creatorModalOpen, setCreatorModalOpen] = useState(false)

  const handleLearnMore = () => {
    window.scrollBy({ top: window.innerHeight, behavior: 'smooth' })
  }

  const handleCreatorModalOpen = () => {
    setCreatorModalOpen(true)
  }
  const isMobile = mobile({ tablet: true })

  return (
    <Container>
      <VideoContainer>
        <VideoBackground
          src="assets/mp4/header-bg.mp4"
          opacity={0.4}
          autoPlay
          loop
          muted
          playsInline
        />
        <DarkCover />
      </VideoContainer>
      <Content>
        <Title>Your Love for Music Will Change the World.</Title>
        <Subtitle>
          Join the community where artists, fans, and investors come together to
          shape the future of music.
        </Subtitle>
        <IconSection>
          <IconLink
            imagePath="/assets/images/updated-homepage/fans-icon.png"
            buttonLink={process.env.REACT_APP_FANDOM_PAGE}
            buttonText="Join the fandom experience"
          />
          <IconLink
            imagePath="/assets/images/updated-homepage/artist-icon.png"
            buttonText="Create your artist account"
            callback={handleCreatorModalOpen}
            buttonLink={isMobile ? 'https://creators.tunego.com/' : ''}
          />
        </IconSection>
        <LearnMore onClick={handleLearnMore}>
          <div>Learn More</div>
          <div>
            <img src="/assets/svg/ic-chevron-down.svg" alt="Chevron down" />
          </div>
        </LearnMore>
        {!isMobile && (
          <CreatorModal
            isOpened={creatorModalOpen}
            setIsOpened={setCreatorModalOpen}
          />
        )}
      </Content>
    </Container>
  )
}

export default WelcomeSection
