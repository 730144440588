import React from 'react'
import styled from 'styled-components'
import InfoBlock from './components/InfoBlock'

const Container = styled.div`
  padding-bottom: 10rem;
  padding-inline: 2rem;
`

const InfoSection = () => {
  return (
    <Container>
      <InfoBlock
        title={'Unlock the Power of Fandom'}
        description={
          "Turn your passion for music into unforgettable experiences. By purchasing music, connecting with your favorite artists, and attending concerts, you'll unlock exclusive opportunities only available to superfans."
        }
        buttonText={'Join the fandom experience'}
        buttonLink={process.env.REACT_APP_FANDOM_PAGE}
        imagePath={'/assets/images/updated-homepage/fans-img.png'}
      />
      <InfoBlock
        reverse
        title={'Empower Your Music Career'}
        description={
          "Take control of your fan relationships, sell music directly, and unlock new revenue streams with TuneGO's patented global technology platform.” Trusted by global superstars and leading music companies."
        }
        buttonText={'Create your artist account'}
        buttonLink={'https://creators.tunego.com/'}
        imagePath={'/assets/images/updated-homepage/artists-img.png'}
      />
    </Container>
  )
}

export default InfoSection
