import React from 'react'
import styled from 'styled-components'
import FooterLink from './components/FooterLink'

const Container = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border: solid 1px ${({ theme: { colors } }) => colors.darkGrey};
  border-top: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.primary_50};
  background-color: ${({ theme: { colors } }) => colors.charcoal};
`

const LinkGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1280px;
  margin: auto;
  margin-bottom: 3rem;

  @media (max-width: 1280px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
`

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const Logo = styled.img`
  max-width: 10rem;
  margin: auto;
`

const Copyright = styled.div`
  font-family: 'AvenirNextLTPro-regular', Arial, serif;
  font-weight: bold;
  font-size: 0.6rem;
  margin: auto;
  margin-top: 1rem;
  white-space: pre-line;
  text-align: center;
  opacity: 0.3;
`

const SmallLinkContainer = styled.div`
  font-family: 'AvenirNextLTPro-regular', Arial, serif;
  font-weight: bold;
  font-size: 0.6rem;
  margin: auto;
  margin-top: 1rem;
  white-space: pre-line;
  text-align: center;

  & > span {
    opacity: 0.3;
  }
`

const SmallLink = styled.a`
  font-family: 'AvenirNextLTPro-regular', Arial, serif;
  font-weight: bold;
  font-size: 0.6rem;
  cursor: pointer;
  opacity: 0.3;
  color: white;

  &:hover {
    text-decoration: underline;
    color: ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.primary_50};
    opacity: 1;
  }
`

const SocialLinkGroup = styled.div`
  margin: auto;
  margin-top: 1rem;
`

const SocialLink = styled.a`
  font-family: 'AvenirNextLTPro-regular', Arial, serif;
  font-weight: bold;
  font-size: 0.6rem;
  cursor: pointer;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  opacity: 0.3;

  &:hover {
    opacity: 1;
    color: ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.primary_50};
    border-bottom: 1px solid
      ${({
        theme: {
          tunego: { COLORS },
        },
      }) => COLORS.primary_50};
  }
`

const Footer = () => {
  return (
    <Container>
      <LinkGroup>
        <FooterLink
          text="Join Fandom Experience"
          link={process.env.REACT_APP_FANDOM_PAGE}
        />
        <FooterLink
          text="Create Artist Account"
          link="https://creators.tunego.com/"
        />
        <FooterLink
          text="Live Shows & Events"
          link="https://fandom.tunego.com/live/"
        />
        <FooterLink text="TuneGO Blog" link="https://blog.tunego.com/" />
      </LinkGroup>
      <BottomSection>
        <Logo src="/assets/svg/ic-tunego-logo.svg" alt="TuneGO Logo" />
        <Copyright>
          {'Copyright © 2024. TuneGO, Inc.\nAll Rights Reserved.'}
        </Copyright>
        <SmallLinkContainer>
          <SmallLink href="https://tunego.com/terms-of-use">
            Terms & Conditions
          </SmallLink>{' '}
          <span>|</span>{' '}
          <SmallLink href="https://tunego.com/privacy-policy">
            Privacy Policy
          </SmallLink>
        </SmallLinkContainer>
        <SocialLinkGroup>
          <a href="https://www.facebook.com/tunego/">
            <SocialLink>
              <img
                src="assets/svg/company-icons/facebook-f.svg"
                alt="Facebook"
              />
            </SocialLink>
          </a>
          <a href="https://www.instagram.com/tunego/?hl=en">
            <SocialLink>
              <img
                src="assets/svg/company-icons/instagram.svg"
                alt="Instagram"
              />
            </SocialLink>
          </a>
          <a href="https://twitter.com/tunego?lang=en">
            <SocialLink>
              <img src="assets/svg/x-logo.svg" alt="x logo" width={20} />
            </SocialLink>
          </a>
        </SocialLinkGroup>
      </BottomSection>
    </Container>
  )
}

export default Footer
